import React, { useState } from "react";

const DescriptionComponent = ({ packs }) => {
  const [isExpanded, setIsExpanded] = useState(false); // State to manage expanded/collapsed view
  const maxLength = 100; // Maximum characters to display when collapsed

  const toggleExpand = () => {
    setIsExpanded(!isExpanded); // Toggle between expanded and collapsed states
  };

  // Function to replace "Dolch", "Fry", and "sight words" with hyperlinks
  const replaceTextWithLinks = (text) => {
    const dolchUrl = "https://en.wikipedia.org/wiki/Dolch_word_list";
    const fryUrl = "https://sightwords.com/sight-words/fry";
    const satUrl = "https://en.wikipedia.org/wiki/Sight_word";

    return (
      <span
        dangerouslySetInnerHTML={{
          __html: text
            .replace(/Dolch/g, `<a href="${dolchUrl}" target="_blank" rel="noopener noreferrer">Dolch</a>`)
            .replace(/Fry/g, `<a href="${fryUrl}" target="_blank" rel="noopener noreferrer">Fry</a>`)
            .replace(/sight words/g, `<a href="${satUrl}" target="_blank" rel="noopener noreferrer">sight words</a>`),
        }}
      />
    );
  };
  const getRandomColor = () => {
    const colors = [
      "#8B0000", // Dark Red
      "#2E8B57", // Sea Green
      "#1C1C1C", // Very Dark Gray
      "#4B0082", // Indigo
      "#483D8B", // Dark Slate Blue
      "#2F4F4F", // Dark Slate Gray
      "#191970", // Midnight Blue
      "#8B4513", // Saddle Brown
      "#006400", // Dark Green
      "#3B3B3B", // Charcoal
    ];
    return colors[Math.floor(Math.random() * colors.length)];
  };
  const renderDescription = () => {
    if (!packs?.Discription) {
      return "No Description Available";
    }

    const descriptionLines = packs.Discription.split("<br>");

    if (isExpanded) {
      return (
        <>
          <ul className="list-group" style={{ listStyleType: "disc", paddingLeft: "20px" }}>
            {descriptionLines.map((line, index) => (
              <li  className="list-group-item" key={index} > <span
              style={{
                backgroundColor: getRandomColor(),
                color: "white",
                fontWeight: "bold",
                borderRadius: "50%",
                display: "inline-block",
                width: "20px",
                height: "20px",
                textAlign: "center",
                lineHeight: "20px",
                marginRight: "10px",
              }}
            >
              ●
            </span> {replaceTextWithLinks(line)} {index==descriptionLines.length-1?<span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={toggleExpand}
              >
                {"...view Less"}
              </span>:""}</li>
            ))}
          </ul>
          
        </>
      );
    } else {
      const shortDescription = descriptionLines.join(" ").substring(0, maxLength) + "...";
      return (
        <>
          {replaceTextWithLinks(shortDescription)}{" "}
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={toggleExpand}
          >
            view More
          </span>
        </>
      );
    }
  };

  return <div>{renderDescription()}</div>;
};

export default DescriptionComponent;
