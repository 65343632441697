import React, { useEffect, useRef, useState } from "react";

import "../../assets/css/styles.css";
import "../../assets/css/animate.min.css";

import axios from "axios";
import Swal from "sweetalert2";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import PlayGround from "./PlayGround";
import { usePreference } from "../../context/UserContext";
import QuizTimer from "./QuizTimer";
import ScroeModal from "./ScroeModal";
import { FaClock } from "react-icons/fa";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiFillHome,
  AiFillMacCommand,
  AiOutlineComment,
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineStepForward,
} from "react-icons/ai";
import {
  ArrowForwardIosRounded,
  ArrowRightAltTwoTone,
  BugReport,
  Close,
  ForwardToInbox,
  Redo,
} from "@mui/icons-material";

const NewUI = () => {
  const navigate = useNavigate();

  const {
    updateIsNavVisible,
    isLogged,
    isCommentOpen,
    updateCommetOpen,
    comment,
    updateComment,
  } = usePreference();
  useEffect(() => {
    updateIsNavVisible(false);
  }, []);

  const { id } = useParams();
  const location = useLocation();

  const [cat, setCat] = useState(0);

  const [subcat, setSubCat] = useState(0);
  const [type, setType] = useState(0);
  const [quizId, setQuizId] = useState(0);
  const [openQuizModel, setOpenQuizModel] = useState(false);
  const [corrects, setCorrects] = useState(0);
  const [wrongs, setWrongs] = useState(0);

  const [tanswers, settAnswers] = useState([]);

  const [answers, setAnswers] = useState("");

  const [dictionary, setDictionary] = useState({});

  const [questions, setQuestions] = useState([]);
  const [currentquestion, setCurrentQuestion] = useState({});

  const [currentIndex, setCurrentIndex] = useState(0);

  const [cheers, setCheers] = useState(true);

  const [audiolink, setAudioLink] = useState("");
  const [quiztype, setQuizType] = useState(0);

  const updateAnswers = (a) => {
    setAnswers(a);
  };

  const updateQuizType = (q) => {
    setQuizType(q);
  };

  const updateCheers = (c) => {
    setCheers(c);
  };

  const updateAudioLink = (a) => {
    setAudioLink(a);
  };
  const updateCurrentQuestion = (q) => {
    setCurrentQuestion(q);
  };

  const updateCurrentIndex = (i) => {
    setCurrentIndex(i);
  };

  const setCategory = (cat) => {
    setCat(cat);
    setSubCat(0);
  };
  const setSubCategory = (subid) => {
    setSubCat(subid);
  };
  const setSubType = (type) => {
    setType(type);
  };

  const setOpenQuizzesModel = (status) => {
    setOpenQuizModel(status);
  };

  const setQuizzId = (qid) => {
    setQuizId(qid);
  };

  const updateCorrect = (c) => {
    //////console.log("corrects ",corrects)
    setCorrects(c);
  };
  const updateWrongs = (w) => {
    ////console.log("wrongs ",wrongs)
    setWrongs(w);
  };

  const updatetAnswers = (a) => {
    ////console.log("wrongs ",wrongs)
    settAnswers(a);
  };

  const updateDictionary = (d) => {
    setDictionary(d);
  };
  const updateQuestions = (q) => {
    setQuestions(q);
  };

  const appToken = localStorage.getItem("webtoken");
  const username = localStorage.getItem("username");
  const auth = "Bearer " + appToken;

  useEffect(() => {
    const cat = id ? parseInt(id, 10) : 0;
    //console.log('Quiz ID:', quizId);
    //console.log('Current Path:', location.pathname);
    if (location.pathname == "/") {
    } else if (location.pathname == "/home") {
    }

    setCat(cat);
    // if (quizId == 0) getLastAttempted(username);
    updateTimer("00:00:00");
  }, [id]);

  useEffect(() => {
    function setBackgroundImage(imageUrl) {
      document.body.style.backgroundImage = `url(${imageUrl})`;
      document.body.style.backgroundSize = "cover"; // Ensures the image covers the entire background
      document.body.style.backgroundPosition = "center"; // Centers the image
    }

    // Example usage with a dynamic URL
    const imageUrl = localStorage.getItem("backgroundimage"); // Replace with your dynamic URL
    setBackgroundImage(imageUrl);
  }, []);

  useEffect(() => {
    const OpenNextQuiz = async (catid, username) => {
      try {
        const responce = await axios.get(
          `https://s3napi.s3nsoftware.com/api/QuizDatas/OpenDefaultQuiz?catid=${catid}&username=${username}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        );
        if (responce.status == 200) {
          setQuizId(responce.data);
          console.log("responce", responce.data);
        }
      } catch (error) {}
    };

    if (cat > 0) OpenNextQuiz(cat, username);

    // //console.log('Quiz ID:', quizId);
    // //console.log('Current Path:', location.pathname);

    // if (location.pathname == "/playquiz") {
    // } else if (location.pathname == "/") {
    // } else if (location.pathname == "/home") {
    // }

    // setQuizId(quizId);
  }, [cat]);

  // const parseTimeString = (timeString) => {
  //   const [minutes, seconds] = timeString.split(":").map(Number); // Split by colon and convert to numbers
  //   return { minutes, seconds };
  // };

  // const [fTimer, setFTimer] = useState("00:00"); // For storing final timer value
  // const [qTimer, setQTimer] = useState("00:00"); // Initial value in "mm:ss" format

  // const { minutes, seconds } = parseTimeString(qTimer);

  // const updateTimer = (value) => {
  //   setQTimer(value);
  // };

  // const updatefTimer = (value) => {
  //   setFTimer(value);
  // };

  // const [qTimer, setQTimer] = useState("00:00"); // Default timer value
  // const [fTimer, setFTimer] = useState("00:00"); // Final timer value

  // // Parse the timer string (e.g., "1:30") into minutes and seconds
  // const parseTimeString = (timeString) => {
  //   const [minutes, seconds] = timeString.split(":").map(Number);
  //   return { minutes: minutes || 0, seconds: seconds || 0 }; // Handle missing seconds
  // };

  // // Update the qTimer when new input is provided
  // const updateTimer = (newTime) => {
  //   setQTimer(newTime); // Update the timer input
  // };

  // const { minutes, seconds } = parseTimeString(qTimer); // Parse the current timer value

  const [qTimer, setQTimer] = useState("00:00:00"); // Default timer value
  const [fTimer, setFTimer] = useState("00:00:00"); // Final timer value

  // Validate and format the timer string (e.g., "01:30:45") into hours, minutes, and seconds
  const validateAndParseTime = (timeString) => {
    // Regex to support both "mm:ss" and "hh:mm:ss" formats
    const regex = /^(\d{1,2}):([0-5]?\d)(?::([0-5]?\d))?$/; // Supports optional hh:mm:ss or mm:ss
    const match = timeString.match(regex);

    if (!match) {
      console.error("Invalid time format! Expected 'mm:ss' or 'hh:mm:ss'.");
      return { hours: 0, minutes: 0, seconds: 0 }; // Default to 0:00 if invalid
    }

    const hours = match[3] ? parseInt(match[1], 10) : 0; // If there are 3 parts, first is hours
    const minutes = match[3] ? parseInt(match[2], 10) : parseInt(match[1], 10); // If 3 parts, second is minutes
    const seconds = match[3] ? parseInt(match[3], 10) : parseInt(match[2], 10); // If 3 parts, third is seconds

    // Return all values (hours, minutes, and seconds)
    return { hours, minutes, seconds };
  };

  // // Test cases
  // console.log(validateAndParseTime("1:30"));      // { hours: 0, minutes: 1, seconds: 30 }
  // console.log(validateAndParseTime("0:00:21"));   // { hours: 0, minutes: 0, seconds: 21 }
  // console.log(validateAndParseTime("1:02:45"));   // { hours: 1, minutes: 2, seconds: 45 }

  // Update the qTimer when new input is provided
  const updateTimer = (newTime) => {
    const { hours, minutes, seconds } = validateAndParseTime(newTime); // Validate the input
    if (hours >= 0 && minutes >= 0 && seconds >= 0) {
      setQTimer(newTime); // Update the timer input only if valid
    } else {
      console.error("Timer input is invalid!");
    }
  };

  // Parsing current timer value
  const { hours, minutes, seconds } = validateAndParseTime(qTimer);

  const handleSaveQuiz = async (ans, dict, qs) => {
    //console.log("submit", "called");
    //console.log("answers", answers);
    //console.log("dictionary", dictionary);

    //console.log("answers", ans);
    //console.log("dictionary", dict);

    const quiz = {
      UserId: username,
      QuizId: quizId,
      QuizAnswers: ans,
      QuizDate: new Date().toISOString(),
      NoOfAttempts: 1,
      FirstAttemptOn: new Date().toISOString(),
      LastAttemptOn: new Date().toISOString(),
      QuizStatus: qs,
      IncAns: "",
      QuizTime: fTimer,
    };

    if (dict && Object.keys(dict).length > 0) {
      quiz.IncAns = Object.entries(dict)
        .map(([key, value]) => `${key},${value}`)
        .join(";");
    }

    await saveUserQuiz(quiz);

    // // Replace with your API endpoint and appropriate method to save the quiz
    // const response = await fetch('https://s3napi.s3nsoftware.com/api/saveQuiz', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify(quiz)
    // });

    // const result = await response.json();
    // return result;
  };

  // const getFormattedDateTime = () => {
  //   const now = new Date();

  //   const year = now.getFullYear();
  //   const month = (now.getMonth() + 1).toString().padStart(2, "0"); // JavaScript months are 0-indexed
  //   const day = now.getDate().toString().padStart(2, "0");

  //   const hours = now.getHours().toString().padStart(2, "0");
  //   const minutes = now.getMinutes().toString().padStart(2, "0");
  //   const seconds = now.getSeconds().toString().padStart(2, "0");

  //   return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  // };

  const saveUserQuiz = async (userQuiz) => {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://s3napi.s3nsoftware.com/api/UserQuizs/SaveUserQuiz",
        headers: {
          "Content-Type": "application/json",
          Authorization: auth.toString(),
        },
        data: JSON.stringify(userQuiz),
      };
      axios
        .request(config)
        .then(async (response) => {
          const result = response.data;
          let res = parseInt(result, 10);

          if (res > 0 && userQuiz.QuizStatus) {
            updateCheers(true);

            const result = await ShowOpenNextQuiz(username);

            if (result && result.length > 0) {
              const splitted = result.split("##");
              Swal.fire({
                title: splitted[0], // Title from the first split value
                text: splitted[3], // Text from the fourth split value
                icon: "warning",
                showCancelButton: true, // Show the Cancel button
                confirmButtonText: "Ok",
                cancelButtonText: "Cancel",
                allowOutsideClick: false, // Disable closing the modal by clicking outside
                allowEscapeKey: false, // Disable closing the modal by pressing the Esc key
                allowEnterKey: false, // Disable the Enter key from triggering the confirm button
                preConfirm: () => {
                  return new Promise((resolve) => {
                    resolve("ok"); // Resolve with a value indicating the OK button was clicked
                  });
                },
              }).then((result) => {
                if (result.isConfirmed) {
                  setQuizId(splitted[2]);
                  // OK button was clicked
                  console.log("OK button clicked");
                  if (splitted[3] && splitted[3].includes("Level")) {
                    window.location.href = "/";
                  }
                } else if (result.isDismissed) {
                  // Cancel button was clicked or modal was dismissed
                  console.log("Cancel button clicked or modal dismissed");
                  setQuizzId(0);
                  updateQuizType(0);
                  setSubCategory(0);
                  window.location.href = "/";
                }
              });
            } else {
              Swal.fire({
                title: "Quiz Completed!",
                text: result,
                icon: "warning",
                confirmButtonText: "Ok",
              });
              setQuizzId(0);
              updateQuizType(0);
              setSubCategory(0);
              window.location.href = "/";
            }
          }
          ////console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          //console.log(error);
          return 0;
        });

      // if (response.ok) {
      //   const result = await response.text();
      //   return parseInt(result, 10);
      // } else {
      //   // Handle HTTP error response
      //   console.error('Failed to save quiz:', response.status);
      //   return 0;
      // }
    } catch (error) {
      // Handle network or other errors
      console.error("Error during save quiz:", error);
      return 0;
    }
  };

  const ShowOpenNextQuiz = async (username) => {
    try {
      const response = await axios.post(
        "https://s3napi.s3nsoftware.com/api/QuizDatas/ShowOpenNextQuiz",
        null, // No data to be sent in the body
        {
          params: {
            Id: quizId,
            firstFind: true,
            username: username,
          },
          headers: {
            Accept: "application/json",
          },
        }
      );
      console.log(response.data);
      return response.data.toString();
    } catch (error) {
      console.error("There was an error making the request:", error);
    }
  };

  const handleClickToHome = () => {
    navigate("/");
  };

  const [quizPath, setQuizPath] = useState("");

  useEffect(() => {
    const getCurrentQuizPath = async (username, quizid) => {
      try {
        // Manually build the URL
        const url = `https://s3napi.s3nsoftware.com/api/UserMaster/CurrentQuizPath?username=${encodeURIComponent(
          username
        )}&quizid=${encodeURIComponent(quizid)}`;

        const response = await axios.post(url, {
          headers: {
            Accept: "application/json",
          },
        });

        if (response.status === 200) {
          setQuizPath(response.data);
          console.log("quizPath " + response.data);
        }
        //console.log(response.data); // Handle the response data
      } catch (error) {
        console.error("Error fetching current quiz path:", error);
      }
    };

    if (quizId > 0) {
      getCurrentQuizPath(username, quizId);
    }
  }, [quizId]);

  const [openAswers, setOpenAnswers] = useState(false);

  const handleClickWordfeedback = () => {
    updateCommetOpen(true);
    const userComments = {
      Username: username,
      CatId: cat,
      Subcat: subcat,
      QuizId: quizId,
      Question: currentquestion.QuizInd,
      FirstInserted: new Date(),
      Comment: "",
      WordId: 123,
    };
    updateComment(userComments);
  };

  const [quizzes, setQuizzes] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const selectHandle = (qid) => {
    setQuizzId(qid);
  };

  useEffect(() => {
    const appToken = localStorage.getItem("webtoken");
    const username = localStorage.getItem("username");
    const URL = `https://s3napi.s3nsoftware.com/api/QuizDatas/GetSubCategories?catid=${cat}&username=${username}`;
    const auth = `Bearer ${appToken}`;

    const getAllQuizzesEx = async (iteration = 1) => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: URL,
          headers: {
            Authorization: auth,
          },
        };

        const response = await axios.request(config);
        setQuizzes(response.data);
        setLoading(false);
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403) &&
          iteration === 1
        ) {
          // await updateToken();
          getAllQuizzesEx(2);
        } else {
          setError(error.message);
          setLoading(false);
        }
      }
    };

    getAllQuizzesEx();
  }, [cat, quizId]);

  useEffect(() => {
    const getAllQuizzesEx = async (iteration = 1) => {
      try {
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: URL,
          headers: {
            Authorization: auth,
          },
        };

        const response = await axios.request(config);
        setQuizzes(response.data);
        setLoading(false);
      } catch (error) {}
    };

    if (quizzes.length == 0) getAllQuizzesEx(1);
  }, [quizzes]);

  const generateLightColor = () => {
    const letters = "CDEF"; // Using only lighter hex digits
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  // Generate a dark random color for outline and text
  const generateDarkColor = () => {
    const letters = "012345"; // Using only darker hex digits
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  };

  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [menuPosition, setMenuPosition] = useState("down"); // State to control if menu shows up or down
  const menuRef = useRef(null);

  const handleMouseEnter = (id, element) => {
    setHoveredItemId(id);

    // Check if there's enough space below the item to display the menu
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // If the space below is less than 150px, show the menu above
    if (windowHeight - rect.bottom < 150) {
      setMenuPosition("up");
    } else {
      setMenuPosition("down");
    }
  };

  // const handleMouseLeave = () => {
  //   setHoveredItemId(null);
  // };

  const handleMenuClick = (action, id) => {
    // Handle menu item clicks here based on action
    console.log(`Action: ${action}, Quiz ID: ${id}`);
  };

  const [incorrectsOnly, setIncorrectsOnly] = useState(false);
  const [clearRetake, setClearRetake] = useState(false);

  const updateIncorrectsOnly = (value) => {
    setIncorrectsOnly(value);
  };
  const updateClearRetake = (value) => {
    setClearRetake(value);
  };

  const handleIncorrect = (id) => {
    // alert("Incorrects")
    setIncorrectsOnly(true);
    selectHandle(id);
  };

  const handleReatake = (id) => {
    // alert("Retake")
    setClearRetake(true);
    selectHandle(id);
  };

  const [showCInc, setShowCInc] = useState(true);
  const [showTimer, setShowTimer] = useState(true);

  const updateShowCInc = (value) => {
    setShowCInc(value);
  };
  const updateShowTimer = (value) => {
    setShowTimer(value);
  };

  // Function to dynamically set menu position (up or down)
  const determineMenuPosition = (target) => {
    const rect = target.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    if (rect.bottom + 100 > windowHeight) {
      setMenuPosition("up");
    } else {
      setMenuPosition("down");
    }
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <section id="hero">
      <div className="container main-container">
        {/* <h5 className="quiz-heading">
          <div style={{ alignContent: "center" }}></div>
          <PathDisplay quizPath={quizPath} />
          <PathDisplay quizPath={quizPath} />
        </h5> */}

        <div id="player">
          <div
            style={{
              alignItems: "end",
              textAlign: "end",
            }}
          >
            <div
              style={{
                alignItems: "end",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "end", // Center horizontally
              }}
            >
              <div>
                <div                    onClick={handleClickToHome}

                  style={{
                    background: "#0551A8",
                    cursor: "pointer",
                    width: "75px",
                    marginRight: "1px",
                    height: "25px",
                    display: "flex", // Use flexbox to align content
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <AiFillHome
                    onClick={handleClickToHome}
                    style={{
                      color: "#fff",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                  />
                  Home
                </div>
              </div>

              {isLogged == "true" ? (
                <div>
                  <div                       onClick={handleClickWordfeedback}

                    style={{
                      background: "#0551A8",
                      cursor: "pointer",
                      width: "100px",
                      marginRight: "1px",
                      height: "25px",
                      display: "flex", // Use flexbox to align content
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <AiOutlineComment
                      onClick={handleClickWordfeedback}
                      style={{
                        color: "#fff",
                        fontSize: "15px",
                        cursor: "pointer",
                      }}
                    />
                    Feedback
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              marginBottom: "-56px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {showCInc && (
                <div
                  onClick={() => setOpenAnswers(true)}
                  onMouseOver={() => setOpenAnswers(true)}
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                >
                  <span
                    style={{
                      color: "green",
                      fontSize: "24px",
                    }}
                  >
                    {corrects}
                  </span>

                  {/* Vertical line using a pipe character */}
                  <span
                    style={{
                      height: "100%",
                      width: "0.5px",
                      backgroundColor: "black", // Use backgroundColor to make it an actual line
                      margin: "0 10px",
                    }}
                  >
                    {/* Intentionally left empty to create the vertical line */}
                  </span>

                  <span
                    style={{
                      color: "red",
                      fontSize: "24px",
                    }}
                  >
                    {wrongs}
                  </span>
                </div>
              )}

              <span
                onClick={() => {
                  updateShowCInc(!showCInc);
                }}
                style={{ marginLeft: "10px" }}
              >
                {showCInc ? <AiOutlineLeft /> : <AiOutlineRight />}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                onClick={() => {
                  updateShowTimer(!showTimer);
                }}
                style={{ marginRight: "10px" }}
              >
                {showTimer ? <AiOutlineRight /> : <AiOutlineLeft />}
              </span>

              <div
                style={{
                  textAlign: "center",
                  display: showTimer ? "block" : "none",
                }}
              >
                <QuizTimer
                  initialHours={hours}
                  initialMinutes={minutes}
                  initialSeconds={seconds}
                  updatefTimer={(value) => {
                    setFTimer(value);
                  }}
                />
              </div>
            </div>
          </div>

          <PlayGround
            quizId={quizId}
            updateWrongs={updateWrongs}
            updateCorrect={updateCorrect}
            corrects={corrects}
            wrongs={wrongs}
            updateDictionary={updateDictionary}
            answers={answers}
            updateAnswers={updateAnswers}
            updatetAnswers={updatetAnswers}
            tanswers={tanswers}
            dictionary={dictionary}
            questions={questions}
            updateQuestions={updateQuestions}
            updateCurrentIndex={updateCurrentIndex}
            currentIndex={currentIndex}
            updateCurrentQuestion={updateCurrentQuestion}
            currentquestion={currentquestion}
            updateCheers={updateCheers}
            cheers={cheers}
            updateAudioLink={updateAudioLink}
            audiolink={audiolink}
            updateQuizType={updateQuizType}
            quiztype={quiztype}
            handleSaveQuiz={handleSaveQuiz}
            setCategory={setCategory}
            setSubCategory={setSubCategory}
            setQuizzId={setQuizzId}
            updateTimer={updateTimer}
            cat={cat}
            incorrectsOnly={incorrectsOnly}
            updateIncorrectsOnly={updateIncorrectsOnly}
            clearRetake={clearRetake}
            updateClearRetake={updateClearRetake}
          />

          <div
            style={{
              backgroundColor: "#E5EBDF",
              display: "flex",
              flexDirection: "column",
              height: "150px",
              padding: "30px",
              overflowY: "auto",
            }}
          >
            {quizzes.map((subcategory, index) => (
              <div
                onMouseLeave={() => {
                  if (hoveredItemId !== null) {
                    setHoveredItemId(null);
                  }
                }}
                key={index}
                style={{ textAlign: "left" }}
              >
                <div
                  className="col-md-12"
                  style={{ marginBottom: "10px" }}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: "10px", // Adjust the space between circles
                    }}
                  >
                    <div
                      className="first-last"
                      style={{
                        display: "inline-block",
                        borderRadius: "10px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        color: "#333",
                        textAlign: "center",
                      }}
                    >
                      {subcategory.SubCatName}
                      {subcategory.WordCount ? (
                        <span style={{ color: "#777", fontSize: "14px" }}>
                          ({subcategory.WordCount}W)
                        </span>
                      ) : (
                        <span style={{ color: "#777", fontSize: "14px" }}>
                          (0W)
                        </span>
                      )}
                    </div>

                    {subcategory.Quizzes.map((item, quizIndex) => (
                      <div key={quizIndex} style={{ position: "relative" }}>
                        <div
                          title={item.QuizName}
                          onMouseEnter={(e) => {
                            handleMouseEnter(item.Id, e.currentTarget);
                            determineMenuPosition(e.currentTarget);
                            setHoveredItemId(item.Id);
                          }} // Pass the element to check positioning
                          //onMouseLeave={handleMouseLeave}
                          style={{
                            backgroundColor:
                              item.Id === quizId
                                ? "#f1c40f"
                                : item.Completed === 1
                                ? "blue"
                                : item.Inprogress === 1
                                ? "#f1c40f"
                                : "#fff",
                            color:
                              item.Id === quizId
                                ? "red"
                                : item.Completed === 1
                                ? "white"
                                : item.Inprogress === 1
                                ? "white"
                                : "green",
                            border:
                              item.Id === quizId
                                ? `1px solid blue`
                                : `1px solid gray`,
                            display: "inline-block",
                            alignContent: "center",
                            textAlign: "center",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            fontWeight: "bold",
                            animation:
                              item.Id === quizId ? "pulse 1s infinite" : "none",
                            transformOrigin: "center",
                            position: "relative",
                          }}
                        >
                          <p style={{ margin: "auto" }}>{item.QuizNumber}</p>
                        </div>

                        {hoveredItemId === item.Id && (
                          <div
                            ref={menuRef}
                            style={{
                              position: "absolute",
                              backgroundColor: "blueviolet",
                              border: "1px solid #ccc",
                              padding: "1px",
                              borderRadius: "4px",
                              width: "auto", // Allow dynamic width
                              zIndex: 2000,
                              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                              // Adjust positioning based on available space
                              top: menuPosition === "down" ? "40px" : "auto",
                              bottom: menuPosition === "up" ? "40px" : "auto",
                            }}
                          >
                            <ul
                              style={{
                                listStyle: "none",
                                margin: 0,
                                padding: 0,
                                display: "flex", // Display items horizontally
                                gap: "10px", // Space between icons
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <li
                                title="Begin/Resume"
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  selectHandle(item.Id);
                                  setHoveredItemId(null);
                                }}
                              >
                                <ArrowRightAltTwoTone size="lg" />
                              </li>
                              <li
                                title="Take Incorrects Only"
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleIncorrect(item.Id);
                                  setHoveredItemId(null);
                                }}
                              >
                                <BugReport size="lg" />
                              </li>
                              <li
                                title="Clear and Retake"
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleReatake(item.Id);
                                  setHoveredItemId(null);
                                }}
                              >
                                <Redo size="lg" />
                              </li>
                              <li
                                title="Close"
                                style={{
                                  color: "white",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setHoveredItemId(null);
                                }}
                              >
                                <Close size="lg" />
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    ))}

                    {/* Keyframe animation defined inside the component */}
                    <style>
                      {`@keyframes pulse {
                        0% {
                            transform: scale(1);
                           }
                        50% {
                            transform: scale(1.2); /* Grow larger in the middle */
                            }
                        100% {
                             transform: scale(1);
                             }
                           }
                      `}
                    </style>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <ScroeModal
          tanswers={tanswers}
          dictionary={dictionary}
          questions={questions}
          openAswers={openAswers}
          setOpenAnswers={setOpenAnswers}
        />
      </div>
    </section>
  );
};

export default NewUI;

<style>
  {`
    @keyframes pulse {
      0% {
        width: 30px;
        height: 30px;
        transform: scale(3);
      }
      50% {
        width: 60px;
        height: 60px;
        transform: scale(1); /* Optionally, also scale up slightly */
      }
      100% {
        width: 30px;
        height: 30px;
        transform: scale(1);
      }
    }
  `}
</style>;
