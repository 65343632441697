import React, { useEffect, useState } from 'react';

const ProgressBar = ({ tanswers = '', quiqsize = [], currentIndex = 0 }) => {
  const [answers, setAnswers] = useState([]);

  useEffect(() => {
    // Ensure tanswers is a string and split it into an array
    if (typeof tanswers === 'string') {
      setAnswers(tanswers.split(''));
    } else {
      setAnswers([]);
    }
  }, [tanswers]);

  return (
    <div
      style={{
        marginTop:'5px',
        display: 'flex',
        width: '100%',
        height: '30px', // Adjust height as needed
        backgroundColor: '#e0e0e0',
        borderRadius: '5px',
        overflow: 'hidden',
      }}
    >
      {/* Ensure length alignment between quiqsize and tanswers */}
      {quiqsize.map((_, index) => (
        <div
          key={index}
          style={{
            flex: 1,
            color: index === currentIndex?'blue':'black',
            backgroundColor:
              index === currentIndex
                ? '#FFD700' // Highlight current index with a different color (Gold)
                : answers[index] === '1'
                ? '#4caf50' // Green for '1'
                : answers[index] === '2'
                ? '#f44336' // Red for '2'
                : '#fff', // White for '0' or undefined
            borderRight: index !== quiqsize.length - 1 ? '1px solid #e0e0e0' : 'none', // Segment divider except for the last one
            transform: index === currentIndex ? 'scale(1.2)' : 'scale(1)', // Zoom for the current index
            transition: 'transform 0.3s ease', // Smooth transition effect
          }}
        >
          {index + 1}
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
