import React, { useEffect, useRef, useState } from "react";
import Play from "../../assets/img/play.png";
import Pause from "../../assets/img/pause.png";

import Bee from "../../assets/img/bee.png";
import AnimatedView from "./AnimatedView";

const AudioPlayer = (props) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play().catch((e) => {
        console.error("Error playing audio:", e);
        // Handle the error (e.g., display an error message)
      });
    }
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
    }
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      pauseAudio();
    } else {
      playAudio();
    }
  };

  useEffect(() => {
    if (props.init) {
      playAudio();
    }
    //console.log(props.audioUrl)
  }, [props.audioUrl, props.currentIndex, props.replay]);

  useEffect(() => {
    const audioElement = audioRef.current;

    const handlePlay = () => {
      setIsPlaying(true);
    };

    const handlePause = () => {
      setIsPlaying(false);
    };

    const handleEnded = () => {
      setIsPlaying(false);
    };

    if (audioElement) {
      audioElement.addEventListener("play", handlePlay);
      audioElement.addEventListener("pause", handlePause);
      audioElement.addEventListener("ended", handleEnded);
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("play", handlePlay);
        audioElement.removeEventListener("pause", handlePause);
        audioElement.removeEventListener("ended", handleEnded);
      }
    };
  }, []);

  const bigIconStyle = {
    cursor: "pointer",
    marginTop: "-90px",
    zIndex: 1000,
    marginLeft: "90px",
    height: "80px",
    width: "80px",
    opacity: 0.8,
    alignContent: "end",
  };

  return (
    <>
      <audio ref={audioRef} src={props.audioUrl} preload="auto" />
      <div id="play-button">
        <img id="question-image" src={Bee} />
        {props.quiz ? (
          // If props.quiz is true, use Font Awesome icons
          <AnimatedView animationType="zoom">
            {isPlaying ? (
              <div style={bigIconStyle} onClick={togglePlayPause}>
                <img style={{ width: "80px", height: "80px" }} src={Pause} />
              </div>
            ) : (
              <div style={bigIconStyle} onClick={togglePlayPause}>
                <img style={{ width: "80px", height: "80px" }} src={Play} />
              </div>
            )}
          </AnimatedView>
        ) : (
          // If props.quiz is false, use React Icons
          <div>
            {isPlaying ? (
              <AnimatedView animationType="zoom">
                <div onClick={togglePlayPause} />
                </AnimatedView> 
            ) : (
              <AnimatedView animationType="zoom">
                <div onClick={togglePlayPause}></div>
                </AnimatedView> 
            )}
          </div>
        )}{" "}
      </div>
    </>
  );
};

export default AudioPlayer;
