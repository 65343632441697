import React, { useState } from "react";
import Swal from "sweetalert2";
import { Close, ShoppingCart } from "@mui/icons-material";
import { usePreference } from "../context/UserContext";
import { Progress } from "reactstrap";
import { CircularProgress } from "@mui/material";

const ShowCart = ({
  showCart,
  toggleCartVisibility,
  cart,
  deleteItem,
  handleCheckout,
  calculateTotal,
  filteredCart,
}) => {
  const [promocode, setPromocode] = useState("");
  const [applied,setApplied]=useState(false);
  const [appliedloder,setAppliedLoder]=useState(false);
  const [codemessage,setCodeMessage]=useState('');

  const {
    updateCart,
  } = usePreference();

  const applyPromocode = async () => {
    const postData = {
      CartItems: filteredCart,
      PromoCode: promocode,

    };

    setApplied(false)
    setAppliedLoder(true)
    const response = await fetch(
      "https://s3napi.s3nsoftware.com/api/UserMasters/ApplyPromocode",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(postData),
      }
    );

    if (response.ok && response.status === 200) {
      response
        .json()
        .then((data) => {
          // Swal.fire({
          //   title: "Success",
          //   text: JSON.stringify(data, null, 2), // Pretty-print JSON as string
          //   icon: "success",
          // });

          updateCartDatainView(data);
          setApplied(true)
          setAppliedLoder(false)
          setCodeMessage(data.Message)
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: "Failed to parse JSON: " + error,
            icon: "error",
          });
          setAppliedLoder(false)

        });
    } else {
      Swal.fire({ title: "failed" });
      setAppliedLoder(false)

    }
  };

  const updateCartDatainView=(responseData)=>{
  // Retrieve local data from local storage
  let localData = JSON.parse(localStorage.getItem('cart')) || [];

  // Iterate over the response cart items and update the price in local data
  responseData.Cart.forEach(responseItem => {
    const localItemIndex = localData.findIndex(
      item =>
        item.packId === responseItem.PackId &&
        item.isCollection === responseItem.IsCollection
    );

    if (localItemIndex > -1) {
      // Update the price in local data if a match is found
      localData[localItemIndex].price = responseItem.Price;
    }
  });

  // Update the local storage with modified data
 localStorage.setItem('cart', JSON.stringify(localData));
 updateCart(localData);

};

  

  return (
    <>
      {showCart && (
        <div
          style={{
            backgroundColor: "#f4f4f4",
            padding: "20px",
            marginTop: "10px",
            marginBottom: "10px",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            position: "fixed",
            top: "0",
            right: "0",
            zIndex: "1000",
            width: "400px",
            maxHeight: "50vh",
            overflowY: "auto",
          }}
        >
          <button
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "red",
              color: "white",
              border: "none",
              borderRadius: "50%",
              cursor: "pointer",
              padding: "2px 10px",
            }}
            onClick={toggleCartVisibility}
          >
            X
          </button>

          <h4>Your Cart</h4>

          <ul
            style={{
              listStyleType: "none",
              padding: "0",
              margin: "0",
            }}
          >
            {cart.length > 0 ? (
              cart.map((item, index) => (
                <li
                  key={index}
                  style={{
                    marginBottom: "10px",
                    backgroundColor: "#fff",
                    padding: "10px",
                    borderRadius: "8px",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <strong>
                        {item.type === "monthly"
                          ? "Monthly Plan"
                          : "Yearly Plan"}
                      </strong>{" "}
                      - ${item.price} - {item.packname}
                    </div>
                    <Close
                      onClick={() => deleteItem(item.packId, item.type)}
                      title="Delete Item"
                      role="button"
                    />
                  </div>
                </li>
              ))
            ) : (
              <p>No items in the cart.</p>
            )}
          </ul>

          {cart.length > 0 && (
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <input
                  value={promocode}
                  onChange={(e) => setPromocode(e.target.value)}
                  text="promocode"
                  name="promocode"
                  placeholder="PROMO CODE"
                />
                <label
                  text="apply"
                  name="name"
                  style={{ cursor: "pointer" }}
                  onClick={applyPromocode}
                >
                 Apply
                </label>
               {appliedloder && <CircularProgress />}

              </div>
              {applied &&
              <label style={{color: codemessage.includes('Not') ? 'red' : 'green' , fontSize:'15px'}} >{ codemessage}</label>
              }
              <button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#28a745",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  cursor: "pointer",
                  marginTop: "10px",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  transition: "background 0.3s ease",
                }}
                onMouseOver={(e) => (e.target.style.background = "#218838")}
                onMouseOut={(e) => (e.target.style.background = "#28a745")}
                onClick={handleCheckout}
              >
                <ShoppingCart
                  style={{ marginRight: "10px", fontSize: "18px" }}
                />{" "}
                Proceed to Checkout - Total: ${calculateTotal()}
              </button>

             
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ShowCart;
