import React, { useState } from 'react';

const AnimatedView = ({ children, animationType }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={{
        transition: 'all 0.5s ease',
        transform: isHovered
          ? animationType === 'zoom'
            ? 'scale(1.1)'
            : animationType === 'rotate'
            ? 'rotate(10deg)'
            : animationType === 'slide'
            ? 'translateX(10px)'
            : 'scale(1)' // Default zoom
          : 'scale(1)', // Reset on mouse out
      }}
    >
      {children}
    </div>
  );
};

export default AnimatedView;
